<template>
  <div class="manufac-box">
    <v-row>
      <v-col cols="6">
        <p class="field-label">
          ผลิตที่
        </p>
        <div class="d-flex">
          <v-radio-group
            v-model="form.manufacCountry"
            :error-messages="manufacCountryError"
            row>
            <v-radio
              v-for="(country, index) in selectOptions.countries"
              :key="`countries-${index}`"
              :label="country.text"
              :value="country.value" />
          </v-radio-group>
          <v-btn
            class="clear-country-btn"
            color="secondary"
            icon
            x-small
            @click="clearCountry()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" />
      <v-col cols="6">
        <v-text-field
          v-model.number="form.sellPrice"
          type="number"
          label="ราคาขาย (บาท)"
          :error-messages="sellPriceError"
          :disabled="!hasManufacCountry"
          outlined
          @keypress="$keypressNumber($event)" />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="form.manufacCost"
          type="number"
          :label="`ค่าผลิต (${isThailand ? 'บาท' : 'หยวน'})`"
          :disabled="!hasManufacCountry"
          :error-messages="manufacCostError"
          outlined
          @keypress="$keypressNumber($event)" />
      </v-col>
    </v-row>
    <v-row
      v-for="(c, i) in form.colors"
      :key="i">
      <v-col cols="12">
        <p class="bold text-uppercase">
          สี {{ c.color }}
        </p>
        <v-divider />
      </v-col>
      <v-col cols="12">
        <span class="bold">รายการอะไหล่</span>
        <v-row>
          <v-col
            v-for="(material, index) in c.materials"
            :key="`material-field-${index}`"
            cols="12">
            <v-row>
              <v-col cols="6">
                <div class="d-flex">
                  <p class="cost-calculate-label">
                    ชื่อ {{ material.name }} =
                    {{ material.currency === 'thb' ? material.unitPrice : `(${material.unitPrice} x 5)` }} x {{ material.amount }} =
                  </p>
                  <p class="cost-calculate-result ml-1">
                    {{ totalCostCal(
                      material.currency === 'cny' ? material.unitPrice * 5 : material.unitPrice,
                      material.amount
                    ) | showFullPriceFormat()
                    }} บาท
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <span class="bold">รายการผ้า</span>
        <v-row>
          <v-col
            v-for="(fabric, index) in c.fabrics"
            :key="`fabric-field-${index}`"
            cols="12">
            <v-row>
              <v-col cols="6">
                <div class="d-flex">
                  <p class="cost-calculate-label">
                    รหัส {{ fabric.fabricNo }} ({{ fabric.hangId }}) =
                    {{ fabric.currency === 'thb' ? fabric.unitPrice : `(${fabric.unitPrice} x 5)` }} x {{ fabric.amount }} =
                  </p>
                  <p class="cost-calculate-result ml-1">
                    {{ totalCostCal(
                      fabric.currency === 'cny' ? fabric.unitPrice * 5 : fabric.unitPrice,
                      fabric.amount
                    ) | showFullPriceFormat()
                    }} บาท
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6">
        <div class="d-flex">
          <p class="cost-calculate-label">
            ราคาต้นทุน
            {{ isThailand ? manufacCost : `(${manufacCost} x 5)` }} + {{ materialsPrice }} + {{ fabricsPrice }} =
          </p>
          <p class="cost-calculate-result ml-1">
            {{ totalCostPrice | showFullPriceFormat() }} บาท
          </p>
        </div>
        <div class="d-flex">
          <p class="cost-calculate-label">
            ราคาไม่รวม VAT = ({{ (form.sellPrice || 0) | showFullPriceFormat() }} / {{ 1.07 }}) =
          </p>
          <p class="cost-calculate-result ml-1">
            {{ (priceWithoutVat || 0) | showFullPriceFormat() }} บาท
          </p>
        </div>
        <div class="d-flex">
          <p class="cost-calculate-label">
            Margin = ({{ (sellPrice / 1.07) | showFullPriceFormat() }} / {{ totalCostPrice | showFullPriceFormat() }}) =
          </p>
          <p class="cost-calculate-result ml-1">
            {{ margin | showFullPriceFormat() }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    margin: {
      type: Number,
      default: 0
    },
    isThailand: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      replacement: [],
      selectOptions: {
        countries: [
          { text: 'ประเทศไทย', value: 'thailand' },
          { text: 'ประเทศจีน', value: 'china' }
        ]
      }
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },
    clothesLength () {
      return this.form.clothesLength || 0
    },
    clothesCostPerMetr () {
      return this.form.clothesCostPerMetr || 0
    },
    manufacCost () {
      return this.form.manufacCost || 0
    },
    sellPrice () {
      return this.form.sellPrice || 0
    },
    materialsPrice () {
      const allMaterials = this.form?.colors?.reduce((arr, c) => [...arr, ...c.materials], []) || []

      return allMaterials.reduce((acc, cur) => {
        if (cur.currency === 'cny') {
          return acc + (cur.amount * cur.unitPrice * 5)
        }

        return acc + (cur.amount * cur.unitPrice)
      }, 0) || 0
    },
    fabricsPrice () {
      const allFabrics = this.form?.colors?.reduce((arr, c) => [...arr, ...c.fabrics], []) || []

      return allFabrics.reduce((acc, cur) => {
        if (cur.currency === 'cny') {
          return acc + (cur.amount * cur.unitPrice * 5)
        }

        return acc + (cur.amount * cur.unitPrice)
      }, 0) || 0
    },
    totalCostPrice () {
      if (this.isThailand) {
        return this.manufacCost + this.materialsPrice + this.fabricsPrice || 0
      }

      return (this.manufacCost * 5) + this.materialsPrice + this.fabricsPrice || 0
    },
    priceWithoutVat () {
      return this.form?.sellPrice ? this.form.sellPrice / 1.07 : 0
    },
    clothesCostPerMetrError () {
      return this.isValid(this.fabricsPrice) ? '' : 'กรุณากรอกราคาผ้า'
    },
    manufacCostError () {
      return this.isValid(this.form.manufacCost) ? '' : 'กรุณากรอกค่าผลิต'
    },
    sellPriceError () {
      return this.isValid(this.form.sellPrice) ? '' : 'กรุณากรอกราคาขาย'
    },
    manufacCountryError () {
      const hasForm = !!this.form.sellPrice || !!this.form.manufacCost || !!this.materialsPrice || !!this.fabricsPrice

      return !this.form.manufacCountry && hasForm ? 'กรุณาเลือกประเทศที่ผลิต' : ''
    },
    hasManufacCountry () {
      return !!this.form.manufacCountry
    }
  },
  watch: {
    replacement: {
      handler (value) {
        this.form.replacement = value
        this.$emit('input', this.form)
      },
      deep: true
    }
  },
  methods: {
    currencyLabel (currency) {
      switch (currency) {
        case 'cny': return 'หยวน'
        case 'thb': return 'บาท'
        default: return currency
      }
    },
    totalCostCal (price, amount) {
      return price * amount || null
    },
    isEmpty (value, alert) {
      return !value ? alert : ''
    },
    clearCountry () {
      this.form.manufacCountry = null
    },
    isValid (data) {
      return !!this.form.manufacCountry && (!!data || data === 0)
    },
    addMoreReplacementField () {
      this.form.replacement.push({ name: null, value: null })
    },
    removeReplacementField (index) {
      this.form.replacement.splice(index, 1)
    },
    replacementCompute (replacement) {
      return replacement?.reduce((total, current) => total + current, 0) || 0
    }
  }
}
</script>

<style>
.field-label {
  font-weight: bold;
  margin: 0px;
}
.cost-calculate-result {
  font-weight: bold;
}
.manufac-box {
  width: 100%;
}
.clear-country-btn {
  margin: 22px 8px 0;
}
.bold {
  font-weight: bold;
}
.capitalize {
  text-transform: capitalize;
}
</style>
