<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="field-label">
          Amount
        </p>
      </v-col>
      <v-col
        v-for="(c, colorIndex) in mappedSkus"
        :key="colorIndex"
        cols="12">
        <p class="field-label text-capitalize">
          {{ c.color }}
        </p>
        <table class="table-product-skus">
          <thead>
            <tr>
              <th>
                <p class="header-label">
                  Size
                </p>
              </th>
              <th>
                <p class="header-label">
                  Color
                </p>
              </th>
              <th>
                <p class="header-label">
                  Percent
                </p>
              </th>
              <th>
                <p class="header-label">
                  Quantity
                </p>
              </th>
              <th>
                <p class="header-label">
                  Ratio
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, skuIndex) in c.skus"
              :key="`sku-detail-${skuIndex}`">
              <td class="text-center text-uppercase">
                {{ sku.size }}
              </td>
              <td class="text-center text-capitalize">
                {{ sku.color }}
              </td>
              <td class="text-center sku-input">
                <v-text-field
                  v-model.number="productSkus[sku.index].percent"
                  type="number"
                  outlined
                  hide-details
                  dense
                  @keyup="reCalcQty($event, c.color)" />
              </td>
              <td class="text-center sku-input">
                <v-text-field
                  v-model.number="productSkus[sku.index].quantity"
                  type="number"
                  outlined
                  hide-details
                  dense
                  disabled />
              </td>
              <td class="text-center sku-input">
                <v-text-field
                  :value="showNumberFormat(productSkus[sku.index].ratio)"
                  outlined
                  hide-details
                  dense
                  disabled />
              </td>
            </tr>
            <tr>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-left pt-8">
                <strong>Total Percent</strong>
                <p :class="calPercent(c.color) === 100 ? '' : 'red--text'">
                  {{ calPercent(c.color) }} %
                  <span v-if="calPercent(c.color) !== 100">
                    <span v-if="calPercent(c.color) > 100">
                      - เกิน {{ calPercent(c.color) - 100 }} %
                    </span>
                    <span v-else>
                      - ขาด {{ 100 - calPercent(c.color) }} %
                    </span>
                  </span>
                </p>
              </td>
              <td class="text-center sku-input pt-6">
                <v-text-field
                  v-model.number="colors[colorIndex].totalQty"
                  type="number"
                  label="Total Quantity"
                  outlined
                  hide-details
                  dense
                  :min="0"
                  @keyup="reCalcQty($event, c.color)" />
              </td>
              <td class="text-center"></td>
            </tr>
          </tbody>
        </table>
        <v-divider class="mt-6" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    productSkus: {
      get () {
        return this.value.productSkus
      },
      set (newVal) {
        this.$emit('input', { ...this.value, productSkus: newVal })
      }
    },
    colors: {
      get () {
        return this.value.colors
      },
      set (newVal) {
        this.$emit('input', { ...this.value, colors: newVal })
      }
    },
    mappedSkus () {
      const indexedSkus = this.productSkus.map((sku, index) => ({ ...sku, index }))
      const colors = this.colors.map((c) => {
        const skus = indexedSkus.filter((sku) => sku.color === c.color)

        return {
          color: c.color,
          totalQty: c?.totalQty || skus.reduce((sum, sku) => sum + Number(sku.quantity || 0), 0),
          skus
        }
      })

      return colors
    }
  },
  methods: {
    showNumberFormat (number = 0) {
      return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    calPercent (color) {
      const mappedSku = this.mappedSkus.find((sku) => sku.color === color)
      const percents = mappedSku?.skus.map((sku) => sku.percent)
      const totalPercent = percents.reduce((total, percent) => {
        if (percent) {
          return total + percent
        }
        return total
      }, 0)
      return totalPercent
    },
    reCalcQty (event, color) {
      if (!this.$keypressNumber(event)) {
        return
      }

      const mappedSku = this.mappedSkus.find((sku) => sku.color === color)

      if (!mappedSku) {
        return
      }

      let total = mappedSku.totalQty

      const sortSkus = [...mappedSku.skus].sort((a, b) => b.percent - a.percent)

      for (const sku of sortSkus) {
        const qty = Math.floor(mappedSku.totalQty * (sku.percent / 100))

        if (total >= qty) {
          this.productSkus[sku.index].quantity = qty
          total -= qty
        } else {
          this.productSkus[sku.index].quantity = total
          total = 0
        }
      }

      while (total > 0) {
        for (const sku of sortSkus) {
          if (total > 0) {
            this.productSkus[sku.index].quantity += 1
            total -= 1
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-product-skus {
  width: 100%;
  .sku-input {
    width: 250px;
  }
}
.header-label {
  font-weight: bold;
}
</style>
